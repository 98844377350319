<template>
  <div class="pb-3" style="padding-left:5%;padding-right:5%;">
    <div class="chart-description-card">

      <!-- <div class="row" v-if="Array.isArray(chartData.functionName)"> -->
      <div class="row d-flex justify-content-around mx-auto" v-if="Array.isArray(chartData.functionName)">
        <div
          v-for="(name, index) in chartData.functionName"
          :key="index"
          :class="[
            'col-sm-6',
            'col-md-4',
            // { 'border-right': index !== chartData.functionName.length - 1 }
          ]"
        >
        
          <div class="function-description chart-small-card">
            <h3 class="bottom-border">{{ chartData.title[index] }}</h3>
            <h4>{{ getDescription(name) }}</h4>
          </div>
        </div>
        
      </div>
      


      
      <div v-else>
        <div class="function-description chart-small-card">
          <h3 class="bottom-border2">{{ chartData.title }}</h3>
          <h4>{{ getDescription(chartData.functionName) }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    chartData: Object
  },
  methods: {
    getDescription(_funcname) {
      console.log("finding",_funcname)
      return window.cubits.api.data.getFunctionDescription(_funcname)
    },
  }
}
</script>

<style scoped>
.bottom-border {
  border-bottom: 3px solid #ccc;
  margin-left: 20%;
  margin-right: 20%;
  padding-top:1%;
  margin-top:1%;
}

.bottom-border2 {
  border-bottom: 3px solid #ccc;
  margin-left: 30%;
  margin-right: 30%;
}

.chart-description-card {
  border-top: 5px ridge #ccc;
  border-bottom: 5px ridge #ccc;
  border-radius: 5px;
  padding-top: 5px;
  margin-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.chart-small-card {
  /* border-top: 1px ridge #ccc;
  border-bottom: 1px ridge #ccc; */
  padding-bottom:1%;
}


.function-description {
  margin-bottom: 1%;
}

.function-description h4 {
  font-size: 18px;
  margin-bottom: 1%;
}

.function-description p {
  font-size: 16px;
  margin: 0;
}
  </style>
  
// src/router.js

import { createRouter, createWebHistory } from 'vue-router';
import StorageCharts from './views/StorageCharts.vue';

const routes = [
  {
    path: '/',
    name: 'WelcomePage',
    meta: {
      title: 'SATISTICS',
      description: 'Decentralized Web3 Insights: Dynamic, Transparent, Trustworthy Data Exploration',
      chartGroup: 'supply'
    },
    component: StorageCharts
  },
  {
    path: '/supply',
    name: 'supply',
    meta: {
        title: 'SATISTICS',
        description: 'Decentralized Web3 Insights: Dynamic, Transparent, Trustworthy Data Exploration',
        chartGroup: 'supply'
    },
    component: StorageCharts
  },
  {
    path: '/storage',
    name: 'storage',
    meta: {
        title: 'SATISTICS',
        description: 'Decentralized Web3 Insights: Dynamic, Transparent, Trustworthy Data Exploration',
        chartGroup: 'storage'
    },
    component: StorageCharts
  },
  {
    path: '/nodes',
    name: 'nodes',
    meta: {
        title: 'SATISTICS',
        description: 'Decentralized Web3 Insights: Dynamic, Transparent, Trustworthy Data Exploration',
        chartGroup: 'nodes'
    },
    component: StorageCharts
  },
  {
    path: '/multisig',
    name: 'multisig',
    meta: {
        title: 'SATISTICS',
        description: 'Decentralized Web3 Insights: Dynamic, Transparent, Trustworthy Data Exploration',
        chartGroup: 'multisig'
    },
    component: StorageCharts
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;

<template>
  <!-- <v-app :class="['bgTheme', darkTheme ? 'dark-theme' : 'bright-theme']"> -->
  <div>
    <div id="app" :class="['bgTheme', darkTheme ? 'dark-theme' : 'bright-theme']">
      <div class="headerBar" id="header">
        <router-link to="/">
          <img class="headerLogo" :src="darkTheme ? require('@/assets/images/storageprotocol/storage_protool_logo.svg') : require('@/assets/images/storageprotocol/storage_protool_logo.svg')" alt="Logo" /> <!-- Change src based on theme -->
        </router-link>
        <router-link to="/storage">Storage Statistics</router-link>
        <router-link to="/nodes">Node Statistics</router-link>
        <router-link to="/supply">Supply Statistics</router-link>
        <router-link to="/multisig" style="margin-right: 60px;">Multisig Statistics</router-link>
        <!-- <router-link to="/sdk">SDK</router-link> -->
        <!-- <router-link to="/download" style="margin-right: 60px;">Download</router-link> -->

        <!-- <router-link to="/download">Download</router-link> -->
        <!-- <button @click="downloadFile">Download</button> -->
        <!-- <button @click="downloadFile">Download</button> -->

        <v-icon @click="toggleTheme" :class="['rounded-icon', darkTheme ? 'dark-theme2' : 'bright-theme2']" style="position: fixed; top: 6px; right: 12px; font-size: 28px;" size="36">
          {{ darkTheme ? 'mdi-weather-sunny' : 'mdi-moon-waning-crescent' }}
        </v-icon>
      </div>

      <!-- Preloader -->
      <!-- <div class="loader-wrapper">
        <div class="loader"></div>
      </div> -->
      <!-- Preloader end -->

      <!-- <div id="main_div" class="main_div main-background" :style="{ backgroundAttachment: 'fixed', backgroundSize :'2600px', backgroundPositionY :'20px', backgroundPositionX :'center', backgroundRepeat :'repeat', backgroundImage: `url(${require('@/assets/images/storageprotocol/bg4k.png')})` }"> -->
      <div id="main_div" :class="['pb-5', !darkTheme ? 'dark-theme3' : 'bright-theme3']" >

        <div class="info-box" v-if="$route.meta" :class="!darkTheme ? 'dark-theme2' : 'bright-theme2'">
            <h2 :class="['p-1 ', !darkTheme ? 'dark-theme' : 'bright-theme']">{{ $route.meta.title }}</h2>

            <p :class="['', darkTheme ? 'dark-theme4' : 'bright-theme4']">{{ $route.meta.description }}</p>
        </div>
        <router-view :key="$route.fullPath" :darkTheme="darkTheme" :chartGroup="$route.meta.chartGroup" /> <!-- Render the routed components -->
        <!-- <v-divider></v-divider>
        <v-divider></v-divider> -->

        <vue-progress-bar></vue-progress-bar> <!-- Include Vue Progressbar if needed -->
      </div>
    </div>

    <!-- <Map></Map> -->
    <FooterBar :darkTheme="darkTheme"></FooterBar>
    <JavaScript></JavaScript>

  </div>

</template>

<script>
import { ref, getCurrentInstance } from "vue";
import FooterBar from "@/components/footer";
import JavaScript from '@/components/JavaScript';

export default {
  components: {
    FooterBar,
    JavaScript
  },
  methods: {

  },
  setup() {
    // Start and finish the progress bar when App.vue is first loaded
    const internalInstance = getCurrentInstance();
    internalInstance.appContext.config.globalProperties.$Progress.start();
    setTimeout(() => {
      internalInstance.appContext.config.globalProperties.$Progress.finish();
    }, 3500);

    const darkTheme = ref(localStorage.getItem('darkTheme') === 'true');

    const setTheme = () => {
      if (darkTheme.value) {
        document.body.style.backgroundColor = '#f0f0f0'; // Change to the desired color
      } else {
        document.body.style.backgroundColor = 'grey'; // Change to the desired color
      }
    }

    const toggleTheme = () => {
      darkTheme.value = !darkTheme.value;
      localStorage.setItem('darkTheme', darkTheme.value.toString());
      setTheme();
    }

    // Call setTheme to set the initial theme based on localStorage
    setTheme();

    return {
      darkTheme,
      toggleTheme
    }
  }
};
</script>

<style>

@import "@/assets/css/fonts.css";
/* Your existing component styles */
  
body {
  margin: 0;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: Roboto,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff; /* Change text color to white */
}

.headerLogo {
  /* position: absolute;
  left: 0px;
  top: 0px; */
  width:600px;

}

.rounded-icon {
  border-radius: 50%;
  width: 40px; /* Adjust the width and height to make it circular */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; /* Remove button background color */
  padding: 0; /* Remove button padding */
  z-index:2;
}



.bgTheme {
  background-attachment: fixed;
  background-size: 150%;
  background-position: 50% 50px;
  background-repeat: repeat;
}


.headerBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
}

.dark-theme {
  /* background-color: #2c3e50  !important;*/
  color: white !important; 
  background-color:black !important;
  /* background-image: url('@/assets/images/storageprotocol/bg4k.png'); */
}

.bright-theme {
  background-color:#0957f3 !important;
  /* background-color: #fff !important; */
  /* color: black !important; */
  color: white !important;
  /* background-image: url('@/assets/images/storageprotocol/bg4k_inverted.png'); */
}

.dark-theme2 {
  background-color:rgb(75, 75, 75) !important;
  color: white !important;
}

.bright-theme2 {
  /* background-color:#b3d5ff !important; */
  background-color:#dce7ff !important;
  color: black !important;
}

.dark-theme3 {
  background-color:rgb(197, 197, 197) !important;
  color: white !important;
}

.bright-theme3 {
  background-color:#8cb0fd !important;
  color: black !important;
}

.dark-theme4 {
  /* background-color:rgb(197, 197, 197) !important; */
  color: #202020 !important;
}

.bright-theme4 {
  /* background-color:rgb(255, 255, 255) !important; */
  color: rgb(188, 188, 188) !important;
}

.dark-theme5 {
  /* background-color:rgb(197, 197, 197) !important; */
  background-color: rgb(189, 189, 189) !important;
  color:rgb(41, 41, 41);
}

.dark-theme5 .v-icon {
  color: rgb(0, 0, 0); /* Set your desired color */
}

.bright-theme5 {
  /* background-color:rgb(255, 255, 255) !important; */
  background-color: rgb(255, 255, 255) !important;
}

.bright-theme5 .v-icon {
  color: rgb(0, 0, 0); /* Set your desired color */
}

.dark-theme6 {
  /* background-color:rgb(197, 197, 197) !important; */
  background-color: rgb(234, 234, 234) !important;
  color:rgb(41, 41, 41);
}



.bright-theme6 {
  /* background-color:rgb(255, 255, 255) !important; */
  background-color: rgb(255, 255, 255) !important;
}




.dark-button {
  background-color:black !important;
}

.bright-button {
  background-color:white !important;
}

#header {
  padding: 10px;
}

#header a {
  font-weight: bold;
  color: #fff; /* Change link color to white */
  text-decoration: none;
  margin-right: 10px;
}

#header button {
  font-weight: bold;
  color: #fff;
  background-color: #42b983;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

#header button:hover {
  background-color: #5cbf9e;
}

#header img {
  height: 80px; /* Adjust the height as needed */
  margin-right: 10px;
  fill: currentColor; /* Make SVG color dynamic */
}

.bright-theme #header img {
  fill: red !important; /* Change fill color for bright theme */
}

.bright-theme #header {
  background-color: #162033;
}

.dark-theme #header {
  background-color: #0957f3; /* Dark background color for header */
}

.info-box {
  /* background-color: #f0f0f0; */

  border: 1px solid #ddd;

}

.info-box h2 {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-size: 1.5rem; /* Adjust font size as needed */
  font-weight: bold;
}

.info-box p {
  /* Additional styles for paragraphs within the info box */
  line-height: 0.5;
}

.info-box table {
  width: 100%;
  border-collapse: collapse;
}

.info-box table td, .info-box table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.info-box table th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: left;
}

</style>
